import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SelezioneComponent } from './components/selezione/selezione.component';
import { ButtonModule } from 'primeng/button';
import { IscrSezAComponent } from './components/iscr-sez-a/iscr-sez-a.component';
import { IscrSezBComponent } from './components/iscr-sez-b/iscr-sez-b.component';
import { IscrSezAPsiComponent } from './components/iscr-sez-a-psi/iscr-sez-a-psi.component';
import { StepperModule } from 'primeng/stepper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpClientModule } from '@angular/common/http';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { PraticheSalvateComponent } from './components/pratiche-salvate/pratiche-salvate.component';
import { AccessoNegatoComponent } from './components/accesso-negato/accesso-negato.component';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from './environment/environment';
//import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

@NgModule({
  declarations: [
    AppComponent,
    SelezioneComponent,
    IscrSezAComponent,
    IscrSezBComponent,
    IscrSezAPsiComponent,
    PraticheSalvateComponent,
    AccessoNegatoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ButtonModule,
    StepperModule,
    ReactiveFormsModule,
    InputTextModule,
    FloatLabelModule,
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    RadioButtonModule,
    FormsModule,
    FieldsetModule,
    FileUploadModule,
    ToastModule,
    HttpClientModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    TableModule,
    DialogModule,
    CommonModule,
    TooltipModule,
    KeycloakAngularModule
  ],
  providers: [/* {
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    multi: true,
    deps: [KeycloakService]
  }, */
  MessageService, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }

//platformBrowserDynamic().bootstrapModule(AppModule);

/* function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: `${environment.keycloakUrl}`,
        realm: `${environment.realm}`,
        clientId: `${environment.clientId}`
      },
      initOptions: {
        onLoad: 'login-required',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
      }
    });
} */
