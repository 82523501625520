import { Component, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Allegati, Allegato, Comune, DatiIscrizione, DocumentiIdentita, DropdownIscrizioniDto, DropdownValues, FileCaricati, FileCaricato, Nazione, Provincia, SezioniAlbo, Titolo, Universita } from '../../models/master-data';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { MasterService } from '../../service/master.service';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-iscr-sez-a',
  templateUrl: './iscr-sez-a.component.html',
  styleUrl: './iscr-sez-a.component.scss',
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })), // Initial state: hidden
      state('*', style({ opacity: 1 })), // Final state: visible
      transition('void <=> *', animate('300ms ease-in-out')), // Animation duration and easing
    ]),
  ]
})
export class IscrSezAComponent implements OnInit{

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
  private master_service = inject(MasterService);

  //valori ottenuti da db
  titoli: Titolo[] = [];
  comuni: Comune[] = [];
  province: Provincia[] = [];
  nazioni: Nazione[] = [];
  universita: Universita[] = [];
  documentiIdentita: DocumentiIdentita[] = [];
  sezioniAlbo: SezioniAlbo[] =[];
  allegati: FileCaricati[] = [];
  provincePuglia: string[] = [
    'Bari',
    'Barletta-Andria-Trani',
    'Brindisi',
    'Foggia',
    'Lecce',
    'Taranto'
  ]
  residenzaInPuglia: boolean = false;
  domicilioInPuglia: boolean = false;

  filtrati: any[] = [];
  bozza: boolean = false;
  invia: boolean = false;
  rifiutata: boolean = false;

  iscrittoProva: DatiIscrizione = {

    sezioneAlbo: {
      id: 0,
      descrizione: '',
      codice: ''
    },

    nome: 'Manuel',
    cognome: 'Centini',
    codiceFiscale: 'CNTMNL93R19H501T',
    sesso: 'M',
    dataNascita: '1993-10-19',
    comuneNascita: {
      codice_istat: "058091",
      nome: "Roma"
    },
    provinciaNascita: {
      codice: 'RM',
      nome: 'Roma'
    },
    cittadinanza: {
      codice: 'IT',
      nome: 'Italia',
      nazionalita: 'Italiana'
    },

    residenzaComune: {
      codice_istat: "058091",
      nome: "Roma"
    },
    residenzaProvincia: {
      codice: 'RM',
      nome: 'Roma'
    },
    residenzaIndirizzo: 'Piazza E. C. Rendina 3',
    residenzaFrazione: '',
    residenzaCap: '00121',
    residenzaStato: {
      codice: 'IT',
      nome: 'Italia',
      nazionalita: 'Italiana'
    },

    domicilioFlag: false,
    domicilioComune: {
      codice_istat: "",
      nome: ""
    },
    domicilioProvincia: {
      codice: '',
      nome: ''
    },
    domicilioIndirizzo: '',
    domicilioFrazione: '',
    domicilioCap: '',
    domicilioStato: {
      codice: '',
      nome: '',
      nazionalita: ''
    },

    tipoDocumento: {
      id: 1,
      descrizione: 'Carta d\'identità',
      codice: 'CI'
    },
    numeroDocumento: 'CA003RM23',
    dataRilascioDocumento: '2021-10-19',
    rilasciatoDa: 'Comune di Roma',

    email: 'manuel@gmail.com',
    pec: '',
    telefono: '',
    cellulare: '3318109053',
    fax: '',

    titolo: null,
    univLaurea: null,
    corsoLaurea: '',
    laureaData: '',

    laureaAbilitante: true,
    universitaAbilitazione: null,
    abilitazioneSessione: '',
    abilitazioneData: '',

    delittiFlag: false,
    altriConsigliFlag: false,
    dipendentePubblico: false,
    dipendentePubblicoPresso: '',
    dipendentePubblicoRuolo: '',
    articolo82: null,

    tesserino: false,

    trattamentoDatiFlag: false

  }

  //dropdown options
  sessoOptions: DropdownValues[] = [
    { name: 'Maschio', value: 'M' },
    { name: 'Femmina', value: 'F' }
  ]

  articolo82Options: any[] = [
    {
      name: "di possedere l'autorizzazione a svolgere la libera professione e ne allegato copia",
      value: true
    },
    {
      name: "di NON possedere l'autorizzazione scritta a svolgere la libera professione",
      value: false
    }
  ]

  //gestione caricamento file
  files: FileCaricato[] = [
    {
      tipo: "Marca_da_Bollo",
      descrizione: "<b>Marca da Bollo da 16,00€:</b>",
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    },
    {
      tipo: "Fototessera",
      descrizione: "<b>Fototessera:</b>",
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    },
    {
      tipo: "Tassa_Concessioni_Governative",
      descrizione: `<b>Ricevuta del versamento della tassa sulle concessioni governative di € 168.00</b> effettuato sul c/c postale 8003
                  intestato a Agenzia delle Entrate - Centro Operativo di Pescara (bollettino prestampato reperibile in qualsiasi ufficio postale)
                  Codice causale n. 8617 - tipo di versamento: "tassa erariale iscrizione Albo Psicologi":`,
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    },
    {
      tipo: "Tassa_iscrizione_ad_Albo",
      descrizione: `<b>Ricevuta del versamento a mezzo bonifico della tassa di iscrizione all'Albo di € 79.00</b> effettuato sul c/c postale
      IBAN: IT90J0760104000000015399702 intestato a Ordine Psicologi Regione Puglia - Via F.lli Sorrentino n.6 - 70126 - Bari, con
      la seguente causale: “Tassa iscrizione Albo 1° anno - nome e cognome richiedente”:`,
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    },
    {
      tipo: "Documento_di_Identità",
      descrizione: "<b>Documento di identità:</b>",
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    },
    {
      tipo: "Tessera_Sanitaria",
      descrizione: "<b>Tessera Sanitaria:</b>",
      nomeFile: "",
      file: null,
      chooseLabel: "Upload"
    }
  ];
  filePermessoSoggiorno: FileCaricato = {
    tipo: "Permesso_Soggiorno",
    descrizione: "I cittadini extracomunitari devono allegare il permesso di soggiorno in corso di validità all'atto della richiesta di iscrizione e, successivamente, inviare alla segreteria dell’Ordine copia dei rinnovi:",
    nomeFile: "",
    file: null,
    chooseLabel: "Upload"
  };
  fileAutorizzazione: FileCaricato = {
    tipo: "Autorizzazione",
    descrizione: "Copia dell'Autorizzazione della Libera Professione",
    nomeFile: "",
    file: null,
    chooseLabel: "Upload"
  }
  fileTesserino: FileCaricato = {
    tipo: "Tesserino",
    descrizione: "Allegare copia del bonifico",
    nomeFile: "",
    file: null,
    chooseLabel: "Upload"
  }

  //dati iscrizione
  iscrizioneForm = new FormGroup({

    anagrafica: new FormGroup({
      sezioneAlbo: new FormControl<string>({value: 'Sezione A', disabled: true}, Validators.required),

      nome: new FormControl<string>('', Validators.required),
      cognome: new FormControl<string>('', Validators.required),
      codiceFiscale: new FormControl<string>('', [Validators.required, Validators.minLength(16), Validators.maxLength(16)]),
      sesso: new FormControl<DropdownValues|null>(null, Validators.required),
      dataNascita: new FormControl<Date|null>(null, Validators.required),
      comuneNascita: new FormControl<Comune|null>(null, [Validators.required, this.customComuneValidators()]),
      provinciaNascita: new FormControl<Provincia|null>(null, [Validators.required, this.customProvinciaValidators()]),
      cittadinanza: new FormControl<Nazione|null>(null, [Validators.required, this.customNazioneValidators('cittadinanza')]),
      permessoSoggiorno: new FormControl<boolean>({ value: false, disabled: true})
    }),

    abitazione: new FormGroup({
      residenzaComune: new FormControl<Comune|null>(null, [Validators.required, this.customComuneValidators()]),
      residenzaProvincia: new FormControl<Provincia|null>(null, [Validators.required, this.customProvinciaValidators()]),
      residenzaIndirizzo: new FormControl<string>('', Validators.required),
      residenzaFrazione: new FormControl<string>(''),
      residenzaCap: new FormControl<string>('', Validators.required),
      residenzaStato: new FormControl<Nazione|null>(null, [Validators.required, this.customNazioneValidators('stato')]),

      domicilioFlag: new FormControl<boolean>(false),
      domicilioComune: new FormControl<Comune|null>({ value: null, disabled: true}),
      domicilioProvincia: new FormControl<Provincia|null>({ value: null, disabled: true}),
      domicilioIndirizzo: new FormControl<string>({ value: '', disabled: true}),
      domicilioFrazione: new FormControl<string>(''),
      domicilioCap: new FormControl<string>({ value: '', disabled: true}),
      domicilioStato: new FormControl<Nazione|null>({ value: null, disabled: true})
    }),

    documento: new FormGroup({
      tipoDocumento: new FormControl<DocumentiIdentita|null>(null, Validators.required),
      numeroDocumento: new FormControl<string>('', Validators.required),
      dataRilascioDocumento: new FormControl<Date|null>(null, Validators.required),
      rilasciatoDa: new FormControl<string>('', Validators.required)
    }),

    contatti: new FormGroup({
      email: new FormControl<string>('', Validators.required),
      pec: new FormControl<string>(''),
      telefono: new FormControl<string>(''),
      cellulare: new FormControl<string>('', Validators.required),
      fax: new FormControl<string>('')
    }),

    laurea_abilitazione: new FormGroup({
      titolo: new FormControl<Titolo|null>(null, Validators.required),
      univLaurea: new FormControl<Universita|null>(null, Validators.required),
      corsoLaurea: new FormControl<string|null>(null, Validators.required),
      laureaData: new FormControl<Date|null>(null, Validators.required),

      laureaAbilitante: new FormControl<boolean>(true),
      universitaAbilitazione: new FormControl<Universita|null>({ value: null, disabled: true}),
      abilitazioneSessione: new FormControl<string>({ value: '', disabled: true}),
      abilitazioneData: new FormControl<Date|null>({ value: null, disabled: true})
    }),

    dichiarazioni: new FormGroup({
      delittiFlag: new FormControl<boolean>(false, [Validators.required, this.customTrueValidators()]),
      altriConsigliFlag: new FormControl<boolean>(false, [Validators.required, this.customTrueValidators()]),
      dipendentePubblico: new FormControl<boolean>(false),
      dipendentePubblicoPresso: new FormControl<string>({ value: '', disabled: true}),
      dipendentePubblicoRuolo: new FormControl<string>({ value: '', disabled: true}),
      articolo82: new FormControl<boolean|null>({ value: null, disabled: true}),
      autorizzazione: new FormControl<boolean>({ value: false, disabled: true})
    }),

    allegati: new FormGroup({
      allegatiFile: new FormControl<boolean>(false, [Validators.required, this.customAllegatiValidators()])
    }),

    tesserino: new FormGroup({
      tesserino: new FormControl<boolean>(false),
      allegatoTesserino: new FormControl<boolean>({ value: false, disabled: true})
    }),

    trattamentoDati: new FormGroup({
      trattamentoDatiFlag: new FormControl<boolean>(false, [Validators.required, this.customTrueValidators()])
    })

  });

  @ViewChild('fileInput') fileInput: FileUpload | undefined;
  
  ngOnInit(): void {
    
    this.getBozza();
    
    this.dataLoad();
    this.loadInfo();

    this.iscrizioniCheck();
    this.updateLabel(window.innerWidth);
    this.markAllAsDirty(this.iscrizioneForm);

  }

  getBozza(){
    if(this.route.snapshot.data['bozza']){
      this.iscrittoProva = this.route.snapshot.data['bozza'];
      this.bozza = true;
      if(this.iscrittoProva.statoIscrizione!.codice === 'R'){
        this.rifiutata = true;
      }

      this.master_service.getFileAllegati(this.iscrittoProva.id!).subscribe({
        next: (allegati: Allegati[]) => {
          allegati.forEach((allegato) => {
            this.onBeforeUpload(allegato);
          })
          const allFilesUploaded = this.files.every(f => f.nomeFile !== "");
          if(allFilesUploaded){
            this.iscrizioneForm.get('allegati.allegatiFile')!.setValue(allFilesUploaded);
          }
        },
        error: err =>{
          this.messageService.add({ severity: 'error', summary: 'Errore', detail: err.error });
        }
      });
      
    }
  }
  
  //data from db loading
  dataLoad(){
    const dropdownData: DropdownIscrizioniDto = this.route.snapshot.data['dropdown'];
    this.titoli = dropdownData.titoli;
    this.comuni = dropdownData.comuni;
    this.province = dropdownData.province;
    this.nazioni = dropdownData.nazioni;
    this.universita = dropdownData.universita;
    this.documentiIdentita = dropdownData.documentiIdentita;
    this.sezioniAlbo = dropdownData.sezioniAlbo;
  }

  //inserisci le informazioni ottenute dallo spid o da bozza
  loadInfo(){

    if(this.iscrittoProva.domicilioFlag){
      ['domicilioIndirizzo', 'domicilioCap', 'domicilioComune', 'domicilioProvincia', 'domicilioStato'].forEach(field => {
        const control_name = `abitazione.${field}`;
        this.iscrizioneForm.get(control_name)!.enable();
        this.iscrizioneForm.get(control_name)!.setValidators(Validators.required);
      });
      this.iscrizioneForm.get('abitazione.domicilioComune')!.setValidators([Validators.required, this.customComuneValidators()]);
      this.iscrizioneForm.get('abitazione.domicilioProvincia')!.setValidators([Validators.required, this.customProvinciaValidators()]);
      this.iscrizioneForm.get('abitazione.domicilioStato')!.setValidators([Validators.required, this.customNazioneValidators('stato')]);
    }
    if(this.iscrittoProva.laureaAbilitante){
      ['universitaAbilitazione', 'abilitazioneData', 'abilitazioneSessione'].forEach(field => {
        const control_name = `laurea_abilitazione.${field}`;
        this.iscrizioneForm.get(control_name)!.enable();
        this.iscrizioneForm.get(control_name)!.setValidators(Validators.required);
      })
    }
    if(this.iscrittoProva.dipendentePubblico){
      ['dipendentePubblicoPresso', 'articolo82'].forEach(field => {
        const control_name = `dichiarazioni.${field}`;
        this.iscrizioneForm.get(control_name)!.enable();
        this.iscrizioneForm.get(control_name)!.setValidators(Validators.required);
      })
      this.iscrizioneForm.get('dichiarazioni.dipendentePubblicoRuolo')!.enable();
    }
    if(this.iscrittoProva.cittadinanza !== null && this.iscrittoProva.cittadinanza.nazionalita !== "Italiana"){
      this.iscrizioneForm.get('anagrafica.permessoSoggiorno')!.enable();
      this.iscrizioneForm.get('anagrafica.permessoSoggiorno')!.setValidators([Validators.required, this.customAllegatiValidators()]);
    }
    this.articolo82Check(this.iscrittoProva.articolo82);
    this.checkAbitazionePuglia();
    this.tesserinoCheck(this.iscrittoProva.tesserino);

    this.iscrizioneForm.patchValue({

      anagrafica: {
        nome: this.iscrittoProva.nome,
        cognome: this.iscrittoProva.cognome,
        codiceFiscale: this.iscrittoProva.codiceFiscale,
        sesso: this.dropdownSearch(this.iscrittoProva.sesso, this.sessoOptions),
        dataNascita: this.iscrittoProva.dataNascita ? new Date(this.iscrittoProva.dataNascita) : null,
        comuneNascita: this.iscrittoProva.comuneNascita,
        provinciaNascita: this.iscrittoProva.provinciaNascita,
        cittadinanza: this.iscrittoProva.cittadinanza
      },

      abitazione: {
        residenzaComune: this.iscrittoProva.residenzaComune,
        residenzaProvincia: this.iscrittoProva.residenzaProvincia,
        residenzaIndirizzo: this.iscrittoProva.residenzaIndirizzo,
        residenzaFrazione: this.iscrittoProva.residenzaFrazione,
        residenzaCap: this.iscrittoProva.residenzaCap,
        residenzaStato: this.iscrittoProva.residenzaStato,

        domicilioFlag: this.iscrittoProva.domicilioFlag,
        domicilioComune: this.iscrittoProva.domicilioComune,
        domicilioProvincia: this.iscrittoProva.domicilioProvincia,
        domicilioIndirizzo: this.iscrittoProva.domicilioIndirizzo,
        domicilioFrazione: this.iscrittoProva.domicilioFrazione,
        domicilioCap: this.iscrittoProva.domicilioCap,
        domicilioStato: this.iscrittoProva.domicilioStato
      },

      documento: {
        tipoDocumento: this.iscrittoProva.tipoDocumento,
        numeroDocumento: this.iscrittoProva.numeroDocumento,
        dataRilascioDocumento: this.iscrittoProva.dataRilascioDocumento ? new Date(this.iscrittoProva.dataRilascioDocumento) : null,
        rilasciatoDa: this.iscrittoProva.rilasciatoDa
      },

      contatti: {
        email: this.iscrittoProva.email,
        pec: this.iscrittoProva.pec,
        telefono: this.iscrittoProva.telefono,
        cellulare: this.iscrittoProva.cellulare,
        fax: this.iscrittoProva.fax,
      },

      laurea_abilitazione: {
        titolo: this.iscrittoProva.titolo,
        univLaurea: this.iscrittoProva.univLaurea,
        corsoLaurea: this.iscrittoProva.corsoLaurea,
        laureaData: this.iscrittoProva.laureaData ? new Date(this.iscrittoProva.laureaData) : null,

        laureaAbilitante: this.iscrittoProva.laureaAbilitante,
        universitaAbilitazione: this.iscrittoProva.universitaAbilitazione,
        abilitazioneSessione: this.iscrittoProva.abilitazioneSessione,
        abilitazioneData: this.iscrittoProva.abilitazioneData ? new Date(this.iscrittoProva.abilitazioneData) : null
      },

      dichiarazioni: {
        delittiFlag: this.iscrittoProva.delittiFlag,
        altriConsigliFlag: this.iscrittoProva.altriConsigliFlag,
        dipendentePubblico: this.iscrittoProva.dipendentePubblico,
        dipendentePubblicoPresso: this.iscrittoProva.dipendentePubblicoPresso,
        dipendentePubblicoRuolo: this.iscrittoProva.dipendentePubblicoRuolo,
        articolo82: this.iscrittoProva.articolo82
      },

      tesserino: {
        tesserino: this.iscrittoProva.tesserino
      },

      trattamentoDati: {
        trattamentoDatiFlag: this.iscrittoProva.trattamentoDatiFlag        
      }

    });

  }

  //gestione dei vari checkbox
  iscrizioniCheck(){
    this.iscrizioneForm.get('anagrafica.cittadinanza')?.valueChanges.subscribe(value => this.permessoSoggiornoCheck(value));
    this.iscrizioneForm.get('abitazione.domicilioFlag')?.valueChanges.subscribe(value => this.domicilioCheck(value));
    this.iscrizioneForm.get('dichiarazioni.dipendentePubblico')?.valueChanges.subscribe(value => this.dipendentePubblicoCheck(value));
    this.iscrizioneForm.get('dichiarazioni.articolo82')?.valueChanges.subscribe(value => this.articolo82Check(value));
    this.iscrizioneForm.get('laurea_abilitazione.laureaAbilitante')?.valueChanges.subscribe(value => this.abilitazioneCheck(value));
    this.iscrizioneForm.get('tesserino.tesserino')?.valueChanges.subscribe(value => this.tesserinoCheck(value));
  }
  permessoSoggiornoCheck(value: any){
    if(value && value.nazionalita !== 'Italiana' && value.nazionalita != null){
      this.iscrizioneForm.get('anagrafica.permessoSoggiorno')!.enable();
      this.iscrizioneForm.get('anagrafica.permessoSoggiorno')!.setValidators([Validators.required, this.customAllegatiValidators()]);
    }
    else{
      this.iscrizioneForm.get('anagrafica.permessoSoggiorno')!.setValue(false);
      this.iscrizioneForm.get('anagrafica.permessoSoggiorno')!.disable();
      this.iscrizioneForm.get('anagrafica.permessoSoggiorno')!.clearValidators();
      this.filePermessoSoggiorno = {
        tipo: "Permesso_Soggiorno",
        descrizione: "I cittadini extracomunitari devono allegare il permesso di soggiorno in corso di validità all'atto della richiesta di iscrizione e, successivamente, inviare alla segreteria dell’Ordine copia dei rinnovi:",
        nomeFile: "",
        file: null,
        chooseLabel: "Upload"
      }
      this.allegati.splice(this.allegati.findIndex(item => item.tipo === "Permesso_Soggiorno"), 1);
    }
    this.iscrizioneForm.get('anagrafica.permessoSoggiorno')?.updateValueAndValidity();
  }
  domicilioCheck(value: any){
    ['domicilioIndirizzo', 'domicilioCap', 'domicilioComune', 'domicilioProvincia', 'domicilioStato'].forEach(field => {
      const control_name = `abitazione.${field}`;
      if(value) {
        this.iscrizioneForm.get(control_name)!.enable();
        this.iscrizioneForm.get(control_name)!.setValidators(Validators.required);
      }else{
        this.iscrizioneForm.get(control_name)!.setValue('');
        this.iscrizioneForm.get(control_name)!.disable();
        this.iscrizioneForm.get(control_name)!.clearValidators();
      }
    });
    if(value){
      this.iscrizioneForm.get('abitazione.domicilioComune')!.setValidators([Validators.required, this.customComuneValidators()]);
      this.iscrizioneForm.get('abitazione.domicilioProvincia')!.setValidators([Validators.required, this.customProvinciaValidators()]);
      this.iscrizioneForm.get('abitazione.domicilioStato')!.setValidators([Validators.required, this.customNazioneValidators('stato')]);
    }
    this.checkAbitazionePuglia();
  }
  dipendentePubblicoCheck(value: any){
    const articolo82 = this.iscrizioneForm.get('dichiarazioni.articolo82');
    const presso = this.iscrizioneForm.get('dichiarazioni.dipendentePubblicoPresso');
    const ruolo = this.iscrizioneForm.get('dichiarazioni.dipendentePubblicoRuolo');
    if(value){
      articolo82?.enable();
      articolo82?.setValidators(Validators.required);
      presso?.enable();
      presso?.setValidators(Validators.required);
      ruolo?.enable();
    }
    else{
      articolo82?.clearValidators();
      articolo82?.setValue(null);
      articolo82?.disable();
      presso?.clearValidators();
      presso?.setValue(null);
      presso?.disable();
      ruolo?.setValue(null);
      ruolo?.disable();
    }
  }
  abilitazioneCheck(value: any){
    ['universitaAbilitazione', 'abilitazioneData', 'abilitazioneSessione'].forEach(field => {
      const control_name = `laurea_abilitazione.${field}`;
      if(value) {
        this.iscrizioneForm.get(control_name)!.enable();
        this.iscrizioneForm.get(control_name)!.setValidators(Validators.required);
      }else{
        this.iscrizioneForm.get(control_name)!.setValue('');
        this.iscrizioneForm.get(control_name)!.disable();
        this.iscrizioneForm.get(control_name)!.clearValidators();
      }
    });
  }
  articolo82Check(value: any){
    if(value){
      this.iscrizioneForm.get('dichiarazioni.autorizzazione')!.enable();
      this.iscrizioneForm.get('dichiarazioni.autorizzazione')!.setValidators([Validators.required, this.customAllegatiValidators()]);
    }
    else{
      this.iscrizioneForm.get('dichiarazioni.autorizzazione')!.setValue(false);
      this.iscrizioneForm.get('dichiarazioni.autorizzazione')!.disable();
      this.iscrizioneForm.get('dichiarazioni.autorizzazione')!.clearValidators();
      this.fileAutorizzazione = {
        tipo: "Autorizzazione",
        descrizione: "Copia dell'Autorizzazione della Libera Professione",
        nomeFile: "",
        file: null,
        chooseLabel: "Upload"
      }
      this.allegati.splice(this.allegati.findIndex(item => item.tipo === "Autorizzazione"), 1);
    }
    this.iscrizioneForm.get('dichiarazioni.autorizzazione')?.updateValueAndValidity();
  }
  tesserinoCheck(value: any){
    if(value){
      this.iscrizioneForm.get('tesserino.allegatoTesserino')!.enable();
      this.iscrizioneForm.get('tesserino.allegatoTesserino')!.setValidators([Validators.required, this.customAllegatiValidators()]);
    }
    else{
      this.iscrizioneForm.get('tesserino.allegatoTesserino')!.setValue(false);
      this.iscrizioneForm.get('tesserino.allegatoTesserino')!.disable();
      this.iscrizioneForm.get('tesserino.allegatoTesserino')!.clearValidators();
      this.fileTesserino = {
        tipo: "Tesserino",
        descrizione: "Allegare copia del bonifico",
        nomeFile: "",
        file: null,
        chooseLabel: "Upload"
      }
      this.allegati.splice(this.allegati.findIndex(item => item.tipo === "Tesserino"), 1);
    }
    this.iscrizioneForm.get('tesserino.allegatoTesserino')?.updateValueAndValidity();
  }

  //Controllo su cittadinanza
  isNotCittadinanzaItaliana(){
    const cittadinanza = this.iscrizioneForm.get('anagrafica.cittadinanza')?.value;
    return cittadinanza?.nazionalita !== 'Italiana' && cittadinanza?.nazionalita != null;
  }
  //Controllo su residenza o domicilio in Puglia
  checkAbitazionePuglia(){
    this.iscrizioneForm.get('abitazione.residenzaProvincia')?.valueChanges.subscribe(value => {
      this.residenzaInPuglia = this.provincePuglia.includes(value!.nome);
      this.checkResDomPuglia();
    });
    this.iscrizioneForm.get('abitazione.domicilioProvincia')?.valueChanges.subscribe(value => {
      if(value?.nome != null){
        this.domicilioInPuglia = this.provincePuglia.includes(value!.nome);
        this.checkResDomPuglia();
      }
      else{
        this.domicilioInPuglia = false;
        this.checkResDomPuglia();
      }
    });
    this.checkResDomPuglia();
    return this.residenzaInPuglia || this.domicilioInPuglia;
  }
  checkResDomPuglia(){
    if(!this.residenzaInPuglia && !this.domicilioInPuglia){
      this.iscrizioneForm.get('abitazione.residenzaProvincia')?.setErrors({ invalid: true });
      this.iscrizioneForm.get('abitazione.domicilioProvincia')?.setErrors({ invalid: true });
    }
    else{
      this.iscrizioneForm.get('abitazione.residenzaProvincia')?.setErrors(null);
      this.iscrizioneForm.get('abitazione.domicilioProvincia')?.setErrors(null);
    }
  }

  //imposta tutti i campi come dirty
  markAllAsDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control?.markAsDirty({ onlySelf: true });
      if (control instanceof FormGroup) {
        this.markAllAsDirty(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach((subControl) => {
          if (subControl instanceof FormGroup) {
            this.markAllAsDirty(subControl);
          }
        });
      }
    });
  }

  //validator per controllo true/false
  customTrueValidators(){
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? null : { notTrue: true };
    };
  }
  //Validator per gli autocomplete
  customComuneValidators(){
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const corrisponde = this.comuni.some(c => c.nome === control.value.nome);
      return corrisponde ? null : { notValid: true };
    }
  }
  customProvinciaValidators(){
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const corrisponde = this.province.some(p => p.nome === control.value.nome);
      return corrisponde ? null : { notValid: true };
    }
  }
  customNazioneValidators(tipo: string){
    if(tipo === 'cittadinanza'){
      return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
          return null;
        }
        const corrisponde = this.nazioni.some(n => n.nazionalita === control.value.nazionalita);
        return corrisponde ? null : { notValid: true };
      }
    }
    else{
      return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
          return null;
        }
        const corrisponde = this.nazioni.some(n => n.nome === control.value.nome);
        return corrisponde ? null : { notValid: true };
      }
    }
  }
  customAllegatiValidators(){
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value ? null : { notValid: true };
    };
  }

  //gestione dropdown
  dropdownSearch(value: string, options: any[]){
    return options.find(option => option.value === value);
  }

  filtro(event: AutoCompleteCompleteEvent, tipo: string) {
    let query = event.query.toLowerCase();
    const queryParts = query.split(" ");

    if(tipo === 'comuni'){
      this.filtrati = this.comuni.filter(comune =>
        queryParts.every(part =>
          comune.nome.toLowerCase().includes(part)
        )
      );
    }
    if(tipo === 'province'){
      this.filtrati = this.province.filter(provincia =>
        queryParts.every(part =>
          provincia.nome.toLowerCase().includes(part)
        )
      );
    }
    if(tipo === 'nazioni'){
      this.filtrati = this.nazioni.filter(nazione =>
        queryParts.every(part =>
          nazione.nome.toLowerCase().includes(part)
        )
      );
    }
    if(tipo === 'cittadinanza'){
      this.filtrati = this.nazioni.filter(nazione =>
        queryParts.every(part =>
          nazione.nazionalita.toLowerCase().includes(part)
        )
      );
    }

    this.filtrati.sort((a, b) => a.nome.localeCompare(b.nome));
  }

  buildDate(data_in: Date){
    const day = String(data_in.getDate()).padStart(2, '0');
    const month = String(data_in.getMonth() + 1).padStart(2, '0');
    const year = String(data_in.getFullYear());
    const data = `${year}-${month}-${day}`;
    return data;
  }

  //gestione caricamento file allegati
  onBasicUploadAuto(event: any, tipo: string) {
    const fileCaricato = event.files[0];
    let fileItem: any;
    if(tipo === "Permesso_Soggiorno"){
      fileItem = this.filePermessoSoggiorno;
      this.iscrizioneForm.get('anagrafica.permessoSoggiorno')!.setValue(true);
    }
    else if(tipo === "Autorizzazione"){
      fileItem = this.fileAutorizzazione;
      this.iscrizioneForm.get('dichiarazioni.autorizzazione')!.setValue(true);
    }
    else if(tipo === "Tesserino"){
      fileItem = this.fileTesserino;
      this.iscrizioneForm.get('tesserino.allegatoTesserino')!.setValue(true);
    }
    else{
      fileItem = this.files.find(file => file.tipo === tipo);
    }
    fileItem.file = fileCaricato;
    fileItem.nomeFile = event.files[0].name;
    fileItem.chooseLabel = "Sovrascrivi";
    const esisteTipo = this.allegati.find(allegato => allegato.tipo === fileItem.tipo);

    if(!esisteTipo){
      this.allegati.push({
        tipo: fileItem.tipo,
        file: fileCaricato
      });
    }
    else{
      esisteTipo.file = fileCaricato;
    }
    console.log(this.allegati);
    
    this.messageService.add({ severity: 'info', summary: 'Caricato', detail: "File caricato correttamente" });

    const allFilesUploaded = this.files.every(f => f.nomeFile !== "");
    if(allFilesUploaded){
      this.iscrizioneForm.get('allegati.allegatiFile')!.setValue(allFilesUploaded);
    }
  }
  onBeforeUpload(allegato: any){
    let fileItem: any;
    if(allegato.tipoFile === "Permesso_Soggiorno"){
      fileItem = this.filePermessoSoggiorno;
      this.iscrizioneForm.get('anagrafica.permessoSoggiorno')!.setValue(true);
    }
    else if(allegato.tipoFile === "Autorizzazione"){
      fileItem = this.fileAutorizzazione;
      this.iscrizioneForm.get('dichiarazioni.autorizzazione')!.setValue(true);
    }
    else if(allegato.tipoFile === "Tesserino"){
      fileItem = this.fileTesserino;
      this.iscrizioneForm.get('tesserino.allegatoTesserino')!.setValue(true);
    }
    else{
      fileItem = this.files.find(file => file.tipo === allegato.tipoFile);
    }
    fileItem.nomeFile = allegato.nomeFile;
    fileItem.chooseLabel = "Sovrascrivi";
    this.updateLabel(window.innerWidth);
  }
  visualizzaAllegato(file: any){
    if(file.file !== null){
      const fileUrl = URL.createObjectURL(file.file);
      window.open(fileUrl, '_blank');
    }
    else{
      const id: number = this.iscrittoProva.id!;
      this.master_service.getFileAllegato(id, file.tipo).subscribe({
        next: (fileBlob: Blob) => {
          const fileUrl = URL.createObjectURL(fileBlob);
          window.open(fileUrl, '_blank');
        },
        error: e => {
          this.messageService.add({ severity: 'error', summary: 'Errore', detail: e.error });
        }
      });
    }
  }
  //apri file trattamento dei dati pdf
  openPdfViewer() {
    const pdfUrl = '/assets/documents/trattamento_dati.pdf';
    window.open(pdfUrl, '_blank');
  }

  //scroll allo stepper corretto al click dei tasti Avanti Indietro
  scrollToElement(className: string) {
    setTimeout(() => {
      const element = document.querySelector(`.${className}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }, 300);
  }

  //gestione tasto Upload in base allo schermo
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateLabel(event.target.innerWidth);
  }
  updateLabel(width: number) {
    const updateChooseLabel = (file: any) => {
      file.chooseLabel = file.nomeFile !== "" ? "Sovrascrivi" : "Upload";
    };
    if (width < 576) {
      this.files.forEach(file => file.chooseLabel = "");
      this.filePermessoSoggiorno.chooseLabel = "";
      this.fileAutorizzazione.chooseLabel = "";
      this.fileTesserino.chooseLabel = "";
    } else {
      this.files.forEach(updateChooseLabel);
      [this.filePermessoSoggiorno, this.fileAutorizzazione, this.fileTesserino].forEach(updateChooseLabel);
    }
  }



  //invia il form a una tabella per le bozze
  generaBozza(){
    
    const iscrizioneData: any = this.generaDomanda();

    this.invia = false;
    
    this.confirmationService.confirm({
      header: 'Salva',
        message: 'Confermare la bozza e salvarla?',
        accept: () => {
          this.master_service.postGeneraBozza(iscrizioneData).subscribe({
            next: (response) => {
              
              this.invioAllegati(iscrizioneData, response.id);

              this.messageService.add({ severity: 'success', summary: 'BOZZA GENERATA', detail: response.message, life: 3000 });
              setTimeout(() => {
                this.router.navigate(['/pratiche_salvate']);
              }, 1000);
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'ERRORE', detail: error.error, life: 3000 });
            }
          });
        },
        reject: () => {
          this.messageService.add({ severity: 'error', summary: 'ANNULLATO', detail: 'Salvataggio annullato', life: 3000 });
        }
    });
  }

  //modificare una bozza salvata
  modificaBozza(){
    
    const iscrizioneData: any = this.generaDomanda();

    this.invia = false;
    
    this.confirmationService.confirm({
      header: 'Salva',
        message: 'Confermare la modifica della bozza e salvarla?',
        accept: () => {
          this.master_service.putModificaBozza(iscrizioneData, this.iscrittoProva.id).subscribe({
            next: (response: string) => {
              
              this.invioAllegati(iscrizioneData, this.iscrittoProva.id!);

              this.messageService.add({ severity: 'success', summary: 'BOZZA MODIFICATA', detail: response, life: 3000 });
              setTimeout(() => {
                this.router.navigate(['/pratiche_salvate']);
              }, 1000);
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'ERRORE', detail: error.error, life: 3000 });
            }
          });
        },
        reject: () => {
          this.messageService.add({ severity: 'error', summary: 'ANNULLATO', detail: 'Salvataggio annullato', life: 3000 });
        }
    });
  }

  //invia il form per la richiesta di iscrizione
  inviaDomanda(){
    
    const iscrizioneData: any = this.generaDomanda();
    
    this.invia = true;
    
    this.confirmationService.confirm({
      header: 'Invia',
        message: 'Confermare il form e inviare la domanda?',
        accept: () => {
          this.master_service.postInviaDomanda(iscrizioneData).subscribe({
            next: (response) => {
              
              this.invioAllegati(iscrizioneData, response.id);

              this.messageService.add({ severity: 'success', summary: 'DOMANDA INVIATA', detail: response.message, life: 3000 });
              setTimeout(() => {
                this.router.navigate(['/']);
              }, 1500);
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'ERRORE', detail: error.error, life: 3000 });
            }
          });
        },
        reject: () => {
          this.messageService.add({ severity: 'error', summary: 'ANNULLATO', detail: 'Invio annullato', life: 3000 });
        }
    });
  }

  //invia il form per la richiesta di iscrizione dalla bozza
  inviaDomandaBozza(){
    
    const iscrizioneData: any = this.generaDomanda();

    this.invia = true;

    this.confirmationService.confirm({
      header: 'Invia',
        message: 'Confermare il form e inviare la domanda?',
        accept: () => {
          this.master_service.putInviaDomandaDaBozza(iscrizioneData, this.iscrittoProva.id).subscribe({
            next: (response: string) => {

              this.invioAllegati(iscrizioneData, this.iscrittoProva.id!);

              this.messageService.add({ severity: 'success', summary: 'DOMANDA INVIATA', detail: response, life: 3000 });
              setTimeout(() => {
                this.router.navigate(['/']);
              }, 1500);
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'ERRORE', detail: error.error, life: 3000 });
            }
          });
        },
        reject: () => {
          this.messageService.add({ severity: 'error', summary: 'ANNULLATO', detail: 'Invio annullato', life: 3000 });
        }
    });
  }

  //invia il form per la richiesta di iscrizione da una domanda già rifiutata
  inviaDomandaRifiutata(){

    const iscrizioneData: any = this.generaDomanda();

    this.invia = true;

    this.confirmationService.confirm({
      header: 'Invia',
        message: 'Confermare il form e inviare la domanda?',
        accept: () => {
          this.master_service.putInviaDomanRifiutata(iscrizioneData, this.iscrittoProva.id).subscribe({
            next: (response: string) => {

              this.invioAllegati(iscrizioneData, this.iscrittoProva.id!);

              this.messageService.add({ severity: 'success', summary: 'DOMANDA INVIATA', detail: response, life: 3000 });
              setTimeout(() => {
                this.router.navigate(['/']);
              }, 1500);
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'ERRORE', detail: error.error, life: 3000 });
            }
          });
        },
        reject: () => {
          this.messageService.add({ severity: 'error', summary: 'ANNULLATO', detail: 'Invio annullato', life: 3000 });
        }
    });
  }

  generaDomanda(){
    const values = this.iscrizioneForm.value;
    const iscrizione = {
      sezioneAlbo: { id: 1 },

      nome: values.anagrafica?.nome!,
      cognome: values.anagrafica?.cognome!,
      codiceFiscale: values.anagrafica?.codiceFiscale!,
      sesso: values.anagrafica?.sesso?.value!,
      dataNascita: values.anagrafica?.dataNascita! ? this.buildDate(values.anagrafica?.dataNascita) : null,
      comuneNascita: values.anagrafica?.comuneNascita!,
      provinciaNascita: values.anagrafica?.provinciaNascita!,
      cittadinanza: values.anagrafica?.cittadinanza!,

      residenzaComune: values.abitazione?.residenzaComune!,
      residenzaProvincia: values.abitazione?.residenzaProvincia!,
      residenzaIndirizzo: values.abitazione?.residenzaIndirizzo!,
      residenzaFrazione: values.abitazione?.residenzaFrazione!,
      residenzaCap: values.abitazione?.residenzaCap!,
      residenzaStato: values.abitazione?.residenzaStato!,

      domicilioFlag: values.abitazione?.domicilioFlag!,
      domicilioComune: values.abitazione?.domicilioComune!,
      domicilioProvincia: values.abitazione?.domicilioProvincia!,
      domicilioIndirizzo: values.abitazione?.domicilioIndirizzo!,
      domicilioFrazione: values.abitazione?.domicilioFrazione!,
      domicilioCap: values.abitazione?.domicilioCap!,
      domicilioStato: values.abitazione?.domicilioStato!,

      tipoDocumento: values.documento?.tipoDocumento!,
      numeroDocumento: values.documento?.numeroDocumento!,
      dataRilascioDocumento: values.documento?.dataRilascioDocumento ? this.buildDate(values.documento?.dataRilascioDocumento) : null,
      rilasciatoDa: values.documento?.rilasciatoDa!,

      email: values.contatti?.email!,
      pec: values.contatti?.pec!,
      telefono: values.contatti?.telefono!,
      cellulare: values.contatti?.cellulare!,
      fax: values.contatti?.fax!,

      titolo: values.laurea_abilitazione?.titolo!,
      univLaurea: values.laurea_abilitazione?.univLaurea!,
      corsoLaurea: values.laurea_abilitazione?.corsoLaurea!,
      laureaData: values.laurea_abilitazione?.laureaData! ? this.buildDate(values.laurea_abilitazione?.laureaData) : null,
      
      laureaAbilitante: values.laurea_abilitazione?.laureaAbilitante!,
      universitaAbilitazione: values.laurea_abilitazione?.universitaAbilitazione!,
      abilitazioneSessione: values.laurea_abilitazione?.abilitazioneSessione!,
      abilitazioneData: values.laurea_abilitazione?.abilitazioneData! ? this.buildDate(values.laurea_abilitazione?.abilitazioneData) : null,

      delittiFlag: values.dichiarazioni?.delittiFlag!,
      altriConsigliFlag: values.dichiarazioni?.altriConsigliFlag!,
      dipendentePubblico: values.dichiarazioni?.dipendentePubblico!,
      dipendentePubblicoPresso: values.dichiarazioni?.dipendentePubblicoPresso!,
      dipendentePubblicoRuolo: values.dichiarazioni?.dipendentePubblicoRuolo!,
      articolo82: values.dichiarazioni?.articolo82!,

      tesserino: values.tesserino?.tesserino!,

      trattamentoDatiFlag: values.trattamentoDati?.trattamentoDatiFlag!
    }
    return iscrizione;
  }

  invioAllegati(iscrizioneData: any, id: number){
    for(let all of this.allegati){
      const allegato: Allegato = {
        idPratica: id,
        codiceFiscale: iscrizioneData.codiceFiscale,
        dimensioneFile: all.file.size,
        tipoFile: all.tipo
      }
      this.master_service.caricaAllegati(all.file, allegato).subscribe({
        next: () => {
        },
        error: (err) => {
          this.messageService.add({ severity: 'error', summary: 'Errore', detail: err.error });
        }
      });
    }
  }

}
