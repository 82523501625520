import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{

  title = 'webapp';

  private router = inject(Router);
  private location = inject(Location);
 /*  private keycloak = inject(KeycloakService);

  isLoggedIn = false;
  userProfile: any;
  userDisplayName?: string; */

  actualRoute: string = this.router.url;
  message: string = "";
  home: boolean = true;

  moduli: string[] = ['iscrizione_sezione_a', 'iscrizione_sezione_b', 'iscrizione_sezione_a_+_psicoterapia'];

  ngOnInit() {

    /* this.keycloakFuntion(); */

    this.checkRouting();

    this.checkRoute();
    
  }

  /* async keycloakFuntion(){

    this.isLoggedIn = await this.keycloak.isLoggedIn();

    if(this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
    }
  } */

  checkRouting(){
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.actualRoute = event.url;
      this.checkRoute();
    });
  }

  goBack(): void {
    this.location.back();
  }

  checkRoute(){
    if(this.router.url.includes('/')){
      this.home = true;
      this.message = "Seleziona un modulo di iscrizione";
    }
    if(this.router.url.includes('/iscrizione_sezione_a')){
      this.home = false;
      this.message = "Modulo di iscrizione Sezione A";
    }
    if(this.router.url.includes('/iscrizione_sezione_b') ){
      this.home = false;
      this.message = "Modulo di iscrizione Sezione B";
    }
    if(this.router.url.includes('/pratiche_salvate') ){
      this.home = false;
      this.message = "Le mie pratiche";
    }
  }

}
