<div class="back">
  <div id="copertina" class="flex flex-column align-items-center">
    <div class="logo mb-2">
      <img class="h-5rem" src="https://psicologipuglia.it/wp-content/uploads/2022/05/logo_new_mag2022.png" alt="logo">
    </div>

    @if(isInviata){
      <p-dialog header="Domanda già inviata" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
        <span class="p-text-secondary block mb-5">Esiste già una domanda inviata, al momento non è possibile effettuarne altre</span>
        <div class="flex justify-content-center gap-2">
          <p-button label="Vai alle Pratiche" severity="primary" (onClick)="vaiAPratiche()" />
        </div>
      </p-dialog>
    }
    @else if(isApprovata){
      <p-dialog header="Domanda già approvata" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
        <span class="p-text-secondary block mb-5">Esiste già una domanda approvata, non se ne possono effettuare altre</span>
        <div class="flex justify-content-center gap-2">
          <p-button label="Vai alle Pratiche" severity="primary" (onClick)="vaiAPratiche()" />
        </div>
      </p-dialog>
    }
    @else{
      <p-dialog header="Ci sono pratiche aperte per la sezione selezionata" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
        <span class="p-text-secondary block mb-5">Aprire le pratiche o effettuare una nuova iscrizione?</span>
        <div class="flex justify-content-center gap-2">
          <p-button label="Vai alle Pratiche" severity="primary" (onClick)="vaiAPratiche()" />
          <p-button label="Nuova Iscrizione" severity="secondary" (onClick)="nuovaIscrizione()" />
        </div>
      </p-dialog>
    }
    
    
    @for (labelName of buttonLabels; track $index) {
      
      <div (click)="confirm(labelName)" class="card text-center flex justify-content-center align-items-center m-6 w-6">
        <p class="p-6 text-xl">{{ labelName.toUpperCase() }}</p>
      </div>
    }
  </div>
</div>
