<div [ngClass]="{'h-screen': home}" class="flex flex-column">
  
  <div class="text-center w-full">
    
    @if (actualRoute === '/') {

      <div class="z-2 fixed w-full flex justify-content-center align-items-center titolo">
        <p class="m-3 font-bold w-2">Ciao</p>        
        <p class="border-solid border-1 border-y-none sm:border-none w-full font-bold p-4 md:text-3xl">{{message}}</p>
        <div class="m-3 cursor-pointer font-bold w-2" [routerLink]="'/pratiche_salvate'">Le mie pratiche</div>
      </div>

    }
    @else if(actualRoute === '/pratiche_salvate'){

      <div class="z-2 fixed w-full flex justify-content-between align-items-center titolo">
        <p-button
          pTooltip="Indietro"
          tooltipPosition="bottom"
          class="backBtn ml-4 w-1"
          icon="pi pi-arrow-circle-left"
          (onClick)="goBack()" />
        <p class="w-6 font-bold py-4 mx-2 md:text-3xl">{{message}}</p>
        <div
          pTooltip="Home"
          tooltipPosition="bottom"
          [routerLink]="'/'"
          class="logo mr-4 cursor-pointer">
          <img class="h-3rem" src="https://psicologipuglia.it/wp-content/uploads/2022/05/logo_new_mag2022.png" alt="logo">
        </div>
      </div>

    }
    @else{

      <div class="z-2 w-full flex justify-content-between align-items-center titolo">
        <p-button
          pTooltip="Indietro"
          tooltipPosition="bottom"
          class="backBtn ml-4 w-1"
          icon="pi pi-arrow-circle-left"
          (onClick)="goBack()" />
        <p class="w-6 font-bold py-4 mx-2 md:text-3xl">{{message}}</p>
        <div
          pTooltip="Home"
          tooltipPosition="bottom"
          [routerLink]="'/'"
          class="logo mr-4 cursor-pointer" >
          <img class="h-3rem" src="https://psicologipuglia.it/wp-content/uploads/2022/05/logo_new_mag2022.png" alt="logo">
        </div>
      </div>

    }
      
  </div>
  <div class="bg-color pt-4">
    <router-outlet></router-outlet>
  </div>
</div>