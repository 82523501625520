import { Component } from '@angular/core';

@Component({
  selector: 'app-accesso-negato',
  templateUrl: './accesso-negato.component.html',
  styleUrl: './accesso-negato.component.scss'
})
export class AccessoNegatoComponent {

}
