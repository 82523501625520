import { Component, HostListener, inject, OnInit } from '@angular/core';
import { MasterService } from '../../service/master.service';
import { PraticheSalvateDto } from '../../models/master-data';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-pratiche-salvate',
  templateUrl: './pratiche-salvate.component.html',
  styleUrl: './pratiche-salvate.component.scss'
})
export class PraticheSalvateComponent implements OnInit {

  private master_service = inject(MasterService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private messageService = inject(MessageService);

  pratiche: PraticheSalvateDto[] = [];
  sezione: string = '';
  message: string = '';
  dialogContent: string = '';
  isInviata: boolean = false;
  isRifiutata: boolean = false;
  isMobile: boolean = false;
  visible: boolean = false;
  visibleElimina: boolean = false;
  eliminaId: number = 0;
  statoColori = new Map<string, string>([
    ['Bozza', '#E1B12C'],
    ['Inviata', '#28A745'],
    ['In Lavorazione', '#E67E22'],
    ['Rifiutata', '#C0392B'],
    ['Approvata', '#3498DB']
  ]);

  ngOnInit(){
    this.loadData();
    this.updateLabel(window.innerWidth);
  }

  loadData(){
    const data = this.route.snapshot.data['pratiche'];
    const inviata = data.some((d: any) => d.statoIscrizione === "Inviata" || d.statoIscrizione === "Approvata" || d.statoIscrizione === "In Lavorazione");
    const rifiutata = data.some((d: any) => d.statoIscrizione === "Rifiutata");
    if(inviata){
      this.isInviata = true;
    }
    if(rifiutata){
      this.isRifiutata = true;
    }
    if(history.state.selezioneRoute){
      this.sezione = history.state.sezione;
      if(this.sezione === ""){
        this.pratiche = data;
      }
      else{
        data.forEach((dato: any) => {
          if(dato.sezioneAlbo === this.sezione){
            this.pratiche.push(dato);
            this.message = ' della Sezione ' + this.sezione;
          }
        })
      }
    }
    else{
      this.pratiche = data;
    }    
  }

  formattaData(dateString: string): string {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  }

  modificaBozza(bozza: PraticheSalvateDto){
    if(this.isInviata){
      this.messageService.add({ severity: 'warn', summary: 'Domanda già Inviata', detail: "Impossibile modificare le bozze, in quanto già presente una domanda inviata" });
    }
    else{
      const bozzaId = bozza.id;
      if(bozza.sezioneAlbo === 'A'){
        this.router.navigate(['/iscrizione_sezione_a', bozzaId]);
      }
      if(bozza.sezioneAlbo === 'B'){
        this.router.navigate(['/iscrizione_sezione_b', bozzaId]);
      }
      if(bozza.sezioneAlbo === 'AP'){
        this.router.navigate(['/iscrizione_sezione_a_+_psicoterapia', bozzaId]);
      }
    }
  }

  stampaBozza(id: number){
    this.master_service.getStampaBozza(id).subscribe({
      next: result => {
        const blob = new Blob([result], { type: 'application/pdf' });        
        const url = window.URL.createObjectURL(blob);        
        window.open(url);
      },
      error: err => {
        console.log(err.error);
      }
    })
  }

  eliminaBozzaDialog(id: number){
    this.visibleElimina = true;
    this.eliminaId = id;
  }
  eliminaBozza(id: number){
    this.master_service.deleteAllegati(id).subscribe({
      next: (response) =>{
        this.master_service.deleteBozza(id).subscribe({
          next: (response) => {
            this.pratiche = this.pratiche.filter(pratiche => pratiche.id !== id);
            this.messageService.add({ severity: 'success', summary: 'Eliminata', detail: response });
          },
          error: (err) => {
            this.messageService.add({ severity: 'error', summary: 'Errore', detail: err.error });
          }
        });
      },
      error: (err) => {
        this.messageService.add({ severity: 'error', summary: 'Eliminata', detail: err.error });
      }
    })
    
    this.visibleElimina = false;
  }

  vediMotivo(motivo: string){
    this.visible = true;
    this.dialogContent = motivo;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateLabel(event.target.innerWidth);
  }
  updateLabel(width: number) {
    if(width < 576) {
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
  }

  

}
