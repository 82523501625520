<div id="container">
    <p class="m-3">Bentornato</p>

    @if(pratiche.length === 0){
        <h3 class="text-xl m-3">Al momento non hai pratiche aperte</h3>
    }
    @else{
        <h3 class="text-xl m-3">Lista pratiche {{message}}:</h3>

        @if(!isMobile){
            <p-table [value]="pratiche" [tableStyle]="{ 'min-width': '50rem' }">
                <ng-template pTemplate="header" >
                    <tr>
                        <th class="text-center">Sezione</th>
                        <th class="text-center">Data</th>
                        <th class="text-center">Modifica</th>
                        <th class="text-center">Elimina</th>
                        <th class="text-center">Stampa</th>
                        <th class="text-center">Stato</th>
                        <th *ngIf="isRifiutata" class="text-center">Motivo Rifiuto</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-pratica>
                    <tr>
                        <td class="text-center">{{ pratica.sezioneAlbo }}</td>
                        <td class="text-center">{{ formattaData(pratica.ultimaOperazioneData) }}</td>
                        @if(pratica.statoIscrizione === 'Bozza'){
                            <td class="text-center"><i (click)="modificaBozza(pratica)" class="text-xl pi pi-pencil cursor-pointer"></i></td>
                            <td class="text-center"><i (click)="eliminaBozzaDialog(pratica.id)" class="text-xl pi pi-trash cursor-pointer"></i></td>
                        }
                        @else if(pratica.statoIscrizione === 'Rifiutata'){
                            <td class="text-center"><i (click)="modificaBozza(pratica)" class="text-xl pi pi-pencil cursor-pointer"></i></td>
                            <td class="text-center"></td>
                        }
                        @else{
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        }
                        <td class="text-center"><i (click)="stampaBozza(pratica.id)" class="text-xl pi pi-print cursor-pointer"></i></td>
                        <td [ngStyle]="{ 'color': statoColori.get(pratica.statoIscrizione) }" class="text-center font-bold">{{ pratica.statoIscrizione }}</td>
                        <td *ngIf="isRifiutata" class="text-center"><i *ngIf="pratica.statoIscrizione === 'Rifiutata'" (click)="vediMotivo(pratica.motivoRifiuto)" class="text-xl pi pi-eye cursor-pointer"></i></td>
                    </tr>
                </ng-template>
            </p-table>
            
            <p-toast position="bottom-center" />
        }
        @else{
            @for(pratica of pratiche; track pratica.id){
                <div class="card w-8 m-6 p-3 flex flex-column">
                    <div class="flex flex-column justify-content-around">
                        <div class="pt-3 flex justify-content-between align-items-center">
                            <p class="m-1"><strong>Sezione:</strong></p>
                            <p class="m-1">{{ pratica.sezioneAlbo }}</p>
                        </div>
                        <div class="pt-3 flex justify-content-between align-items-center">
                            <p class="m-1"><strong>Data:</strong></p>
                            <p class="m-1">{{ formattaData(pratica.ultimaOperazioneData) }}</p>
                        </div>
                        <div class="pt-3 flex justify-content-between align-items-center">
                            <p class="m-1"><strong>Stato:</strong></p>
                            <p class="m-1"><span class="font-bold" [ngStyle]="{ 'color': statoColori.get(pratica.statoIscrizione) }">{{ pratica.statoIscrizione }}</span></p>
                        </div>
                        <div *ngIf="pratica.statoIscrizione === 'Rifiutata'"class="pt-3 flex justify-content-between align-items-center">
                            <p class="m-1"><strong>Motivo rifiuto:</strong></p>
                            <i
                                *ngIf="pratica.statoIscrizione === 'Rifiutata'"
                                (click)="vediMotivo(pratica.motivoRifiuto)"
                                class="text-xl m-1 pi pi-eye cursor-pointer"></i>
                        </div>
                    </div>
                    <div class="p-3 pt-5 flex justify-content-around"> 
                        <i
                            pTooltip="Modifica"
                            tooltipPosition="bottom"
                            (click)="modificaBozza(pratica)"
                            class="text-xl pi pi-pencil cursor-pointer"
                            *ngIf="pratica.statoIscrizione === 'Bozza' || pratica.statoIscrizione === 'Rifiutata'">
                        </i>
                        <i
                            pTooltip="Elimina"
                            tooltipPosition="bottom"
                            (click)="eliminaBozzaDialog(pratica.id)"
                            class="text-xl pi pi-trash cursor-pointer"
                            *ngIf="pratica.statoIscrizione === 'Bozza'">
                        </i>
                        <i
                            pTooltip="Stampa"
                            tooltipPosition="bottom"
                            (click)="stampaBozza(pratica.id)"
                            class="text-xl pi pi-print cursor-pointer"></i>
                    </div>
                </div>
            }
        }
        <p-dialog header="Motivo Rifiuto" [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
            <p>{{ dialogContent }}</p>
        </p-dialog>

        <p-dialog header="Elimina Bozza" [modal]="true" [(visible)]="visibleElimina" [style]="{ width: '25rem' }">
            <p>Vuoi eliminare definitivamente la bozza corrente?</p>
            <div class="flex justify-content-end gap-2 mt-4">
                <p-button label="Annulla" severity="secondary" (onClick)="visibleElimina = false" />
                <p-button label="Elimina" severity="danger" (onClick)="eliminaBozza(eliminaId)" />
            </div>
        </p-dialog>
    }
    
</div>